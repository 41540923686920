import React from 'react';
import TextLockup from '../components/TextLockup';
import Button from '../components/Button';
import { AppIcon } from 'common';
import { PageLayout } from '../components/layout';
import { MainContent } from '../components/layout/main-content';

const CheckInbox = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <MainContent cls="grid grid-cols-5 lg:grid-cols-14 gap-y-8 mt-4 md:mt-8 lg:mt-16 pb-64 lg:pb-96 items-center justify-items-center">
        <AppIcon
          icon="email-checked"
          size={60}
          cls="col-span-full col-start-1 justify-center"
        />
        <TextLockup
          headingText="Please check your inbox"
          paragraph="All you need to do now is confirm your email address."
          headingClassName="col-start-1 col-span-full lg:col-start-4 lg:col-span-8 text-sh-black font-semibold text-center"
          paraClassName="col-start-1 col-span-full lg:col-start-4 lg:col-span-8 justify-self-center text-center text-sh-gray-dark text-sh-h4"
        />
        <Button
          text="OK"
          buttonClasses="sm:leading-none md:leading-normal base no-underline hover:no-underline col-start-1 col-span-full justify-self-center mt-8 font-semibold"
          url={'/'}
        />
      </MainContent>
    </PageLayout>
  );
};

export default CheckInbox;
