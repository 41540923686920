import React, { FC, ReactNode } from 'react';
import is from 'is';

interface lockupProps {
  headingText?: string | JSX.Element;
  subHeadingText?: string;
  paragraph?: string | JSX.Element;
  className?: string;
  headingClassName?: string | undefined;
  subHeadingClassName?: string;
  paraClassName?: string;
}

const getHeader = (text: string, cls: string) => {
  if (!is.string(text)) {
    return <h2 className={`text-sh-h3  md:text-sh-h2 mb-2 md:mb-8 ${cls}`}>{text}</h2>;
  }

  const headingWords = text.split(' ');
  return (
    <h2 className={`text-sh-h3 md:text-sh-h2 mb-2 md:mb-4 ${cls}`}>
      {headingWords.length > 0 &&
        headingWords
          .map<ReactNode>(w => {
            if (w.startsWith('@@'))
              return (
                <span className="border-b-4 border-sh-pink" key={w}>
                  {w.substring(2)}
                </span>
              );
            return w;
          })
          .reduce((prev, curr) => [prev, ' ', curr])}
    </h2>
  );
};

const TextLockup: FC<lockupProps> = ({
  headingText = '',
  paragraph,
  subHeadingText,
  headingClassName = '',
  paraClassName = '',
  subHeadingClassName,
}) => {
  return (
    <>
      {getHeader(headingText as string, headingClassName)}
      {subHeadingText && (
        <h3 className={`text-sh-h4 md:text-sh-h3 ${subHeadingClassName}`}>
          {subHeadingText}
        </h3>
      )}
      {paragraph && <p className={paraClassName}>{paragraph}</p>}
    </>
  );
};

TextLockup.defaultProps = {
  paragraph: '',
  className: 'text-2xl',
};

export default TextLockup;
