import React, { FC } from 'react';
import { Link } from 'gatsby';
import '../styles/buttons.scss';

interface Props {
  text: string;
  url?: string;
  buttonClasses: string;
}

const Button: FC<Props> = ({ text, url, buttonClasses }) => {
  return (
    <Link to={url} className={buttonClasses}>
      {text}
    </Link>
  );
};

Button.defaultProps = {
  text: 'Click!',
  url: '/',
  buttonClasses: 'border-1 rounded-sm p-2',
};

export default Button;
